<template>
  <div>
    <b-alert
      :show="form.errors && form.errors.length > 0"
      variant="light"
      class="alert red lighten-4"
      ref="alert" 
    >
      <div class="alert-icon">
        <i class="flaticon-warning kt-font-danger"></i>
      </div>
      <div class="alert-text">
        <div v-for="err in form.errors" :key="err">
          {{ err }}
        </div>
      </div>
    </b-alert>
    <div class="row">
      <div class="col-md-12">
        <KTPortlet v-bind:title="title">
          <template v-slot:body>
            <v-form 
              ref="form" 
              @submit.prevent="submitForm"
              v-model="form.valid"
              lazy-validation
            >
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="form.name"
                    :disabled="form.loading"
                    :counter="50"
                    :label="$t('HOMEPAGE_BANNER.ADD_EDIT.NAME')"
                    :rules="form.nameRules"
                    required
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="form.order"
                    :disabled="form.loading"
                    :label="$t('HOMEPAGE_BANNER.ADD_EDIT.ORDER')"
                    :rules="form.orderRules"
                    type="number"
                    min="1"
                    required
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    v-model="form.description"
                    :disabled="form.loading"
                    :counter="200"
                    :label="$t('HOMEPAGE_BANNER.ADD_EDIT.DESCRIPTION')"
                    :rules="form.descriptionRules"
                    required
                  ></v-text-field>
                </v-col>

              <v-col
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    v-model="form.descriptionEN"
                    :disabled="form.loading"
                    :counter="200"
                    :label="$t('HOMEPAGE_BANNER.ADD_EDIT.DESCRIPTION_EN')"
                    :rules="form.descriptionENRules"
                    required
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <v-menu
                    ref="startDateMenu"
                    v-model="form.startDateMenu"
                    :disabled="form.loading"
                    :close-on-content-click="false"
                    :nudge-right="5"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="computedStartDateFormatted"
                        :disabled="form.loading"
                        :label="$t('HOMEPAGE_BANNER.ADD_EDIT.START_DATE')"
                        :rules="form.startDateRules"
                        hint="DD/MM/YYYY format"
                        persistent-hint
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker 
                      v-model="form.startDate" 
                      scrollable
                      :disabled="form.loading"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="form.startDateMenu = false">Cancel</v-btn>
                      <v-btn text color="primary" @click="$refs.startDateMenu.save(form.startDate)">OK</v-btn>
                    </v-date-picker>     
                  </v-menu>
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <v-menu
                    ref="startTimeMenu"
                    v-model="form.startTimeMenu"
                    :disabled="form.loading"
                    :close-on-content-click="false"
                    :nudge-right="5"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="form.startTime"
                        :disabled="form.loading"
                        :label="$t('HOMEPAGE_BANNER.ADD_EDIT.START_TIME')"
                        :rules="form.startTimeRules"
                        hint="HH:MM format"
                        persistent-hint
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker 
                      v-model="form.startTime" 
                      format="24hr" 
                      scrollable
                      :disabled="form.loading"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="form.startTimeMenu = false">Cancel</v-btn>
                      <v-btn text color="primary" @click="$refs.startTimeMenu.save(form.startTime)">OK</v-btn>
                    </v-time-picker>     
                  </v-menu>
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <v-menu
                    ref="endDateMenu"
                    v-model="form.endDateMenu"
                    :disabled="form.loading"
                    :close-on-content-click="false"
                    :nudge-right="5"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="computedEndDateFormatted"
                        :disabled="form.loading"
                        :label="$t('HOMEPAGE_BANNER.ADD_EDIT.END_DATE')"
                        :rules="form.endDateRules"
                        hint="DD/MM/YYYY format"
                        persistent-hint
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker 
                      v-model="form.endDate" 
                      scrollable
                      :disabled="form.loading"
                      :min="form.startDate"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="form.endDateMenu = false">Cancel</v-btn>
                      <v-btn text color="primary" @click="$refs.endDateMenu.save(form.startDate)">OK</v-btn>
                    </v-date-picker>     
                  </v-menu>
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <v-menu
                    ref="endTimeMenu"
                    v-model="form.endTimeMenu"
                    :disabled="form.loading"
                    :close-on-content-click="false"
                    :nudge-right="5"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="form.endTime"
                        :disabled="form.loading"
                        :label="$t('HOMEPAGE_BANNER.ADD_EDIT.END_TIME')"
                        :rules="form.endTimeRules"
                        hint="HH:MM format"
                        persistent-hint
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker 
                      v-model="form.endTime" 
                      format="24hr" 
                      scrollable
                      :disabled="form.loading"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="form.endTimeMenu = false">Cancel</v-btn>
                      <v-btn text color="primary" @click="$refs.endTimeMenu.save(form.endTime)">OK</v-btn>
                    </v-time-picker>     
                  </v-menu>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    v-model="form.typeLink"
                    :disabled="form.loading"
                    :items="form.typeLinkItems"
                    hide-no-data
                    hide-selected
                    item-text="text"
                    item-value="value"
                    :label="$t('HOMEPAGE_BANNER.ADD_EDIT.TYPE_LINK')"
                    :placeholder="
                      $t('SHARED.START_TYPING_TO_SEARCH')
                    "
                    @change="clearUrlLink"
                    return-object
                    clearable
                  ></v-autocomplete>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                    <v-autocomplete
                      v-show="form.typeLink ? form.typeLink.value === 'PROMOTION' : false"
                      v-model="form.promotionLink"
                      :disabled="form.loading"
                      :items="form.promotionItems"
                      :loading="form.promotionLoading"
                      :search-input.sync="form.promotionSearch"
                      hide-no-data
                      hide-selected
                      item-text="text"
                      item-value="value"
                      :label="$t('HOMEPAGE_BANNER.ADD_EDIT.URL_LINK')"
                      :placeholder="
                        $t('SHARED.START_TYPING_TO_SEARCH')
                      "
                      return-object
                      clearable
                    ></v-autocomplete>

                    <v-autocomplete
                      v-show="form.typeLink ? form.typeLink.value === 'MENU' : false"
                      v-model="form.menuLink"
                      :disabled="form.loading"
                      :items="form.menuItems"
                      :loading="form.menuLoading"
                      :search-input.sync="form.menuSearch"
                      hide-no-data
                      hide-selected
                      item-text="text"
                      item-value="value"
                      :label="$t('HOMEPAGE_BANNER.ADD_EDIT.URL_LINK')"
                      :placeholder="
                        $t('SHARED.START_TYPING_TO_SEARCH')
                      "
                      return-object
                      clearable
                    ></v-autocomplete>

                    <v-text-field
                      v-show="form.typeLink ? form.typeLink.value === 'EXTERNAL' : false"
                      v-model="form.externalLink"
                      :disabled="form.loading"
                      :counter="450"
                      :label="$t('HOMEPAGE_BANNER.ADD_EDIT.URL_LINK')"
                      :rules="form.externalLinkRules"
                      required
                    ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  md="12"
                >
                  <v-file-input 
                    accept="image/*"
                    show-size
                    v-model="form.picture"
                    :disabled="form.loading"
                    :label="$t('HOMEPAGE_BANNER.ADD_EDIT.PICTURE')"
                    :hint="$t('HOMEPAGE_BANNER.ADD_EDIT.PICTURE_HINT')"
                    persistent-hint
                  ></v-file-input>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                  v-if="form.pictureUrl"
                >
                  <v-img 
                    :v-model="form.pictureUrl"
                    :src="form.pictureUrl" 
                    :disabled="form.loading"
                    aspect-ratio="1.7" 
                    contain
                  >
                  </v-img>
                </v-col>

                <v-col
                  cols="12"
                  md="12"
                >
                  <v-file-input 
                    accept="image/*"
                    show-size
                    v-model="form.pictureEN"
                    :disabled="form.loading"
                    :label="$t('HOMEPAGE_BANNER.ADD_EDIT.PICTURE_EN')"
                    :hint="$t('HOMEPAGE_BANNER.ADD_EDIT.PICTURE_HINT')"
                    persistent-hint
                  ></v-file-input>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                  v-if="form.pictureENUrl"
                >
                  <v-img 
                    :v-model="form.pictureENUrl"
                    :src="form.pictureENUrl" 
                    :disabled="form.loading"
                    aspect-ratio="1.7" 
                    contain
                  >
                  </v-img>
                </v-col>

                <v-col
                  cols="12"
                  md="12"
                >
                  <v-checkbox
                    v-model="form.inActiveStatus"
                    :disabled="form.loading"
                    :label="$t('HOMEPAGE_BANNER.ADD_EDIT.IN_ACTIVE_STATUS')"
                    required
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <div class="col-12">
                  <v-btn
                    :disabled="!form.valid || form.loading"
                    color="success"
                    class="mr-4"
                    tile
                    type="submit"
                  >
                    <v-icon left>la-save</v-icon>
                    {{ $t("SHARED.SAVE_BUTTON") }}
                  </v-btn>
                  <v-btn
                    :disabled="form.loading"
                    color="default"
                    class="mr-4"
                    type="reset"
                    tile
                    @click.prevent="resetForm"
                  >
                    <v-icon left>mdi-eraser</v-icon>
                    {{ $t("SHARED.RESET_BUTTON") }}
                  </v-btn>
                </div>
              </v-row>

              <v-dialog v-model="form.dialog" persistent max-width="300">
                <v-card>
                  <v-card-title class="headline">
                    {{
                      $t("HOMEPAGE_BANNER.ADD_EDIT.SUCCESS_DIALOG_HEADER")
                    }}</v-card-title
                  >
                  <v-card-text>
                      {{ $t("HOMEPAGE_BANNER.ADD_EDIT.EDIT_SUCCESS_DIALOG_TEXT") }}
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="closeDialog"
                      >{{ $t("SHARED.CLOSE_BUTTON") }}</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog
                v-model="form.loading"
                persistent
                hide-overlay
                width="300"
              >
                <v-card>
                  <v-card-title class="headline">
                    {{ $t("SHARED.PLEASE_WAIT") }}</v-card-title
                  >
                  <v-card-text>
                    <p>
                      {{ $t("SHARED.PROCESSING") }}
                    </p>
                    <v-progress-linear
                      indeterminate
                      color="amber lighten-3"
                      class="mb-3"
                    ></v-progress-linear>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-form>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";

export default {
  components: {
    KTPortlet,
  },
  data() {
    return {
      form: {
        valid: true,
        dialog: false,
        loading: false,
        errors: [],
        name: "",
        description: "",
        descriptionEN: "",
        order: null,
        startDateMenu: false,
        startDate: null,
        startDateFormatted: null,
        // minStartDate: new Date().toISOString().substr(0, 10),
        startTime: "00:00",
        startTimeMenu: false, 
        endDateMenu: false,
        endDate: null,
        endDateFormatted: null,
        // minEndDate: this.getTomorrowDate().toISOString().substr(0, 10),
        endTime: "00:00",
        endTimeMenu: false,       
        inActiveStatus: true,
        picture: null,
        pictureEN: null,
        pictureType: [ "image/jpeg", "image/png" ],
        pictureUrl: null,
        pictureENUrl: null,
        bannerId: null,
        typeLink: null,
        urlLink: null,
        promotionLink: null,
        menuLink: null,
        externalLink: null,
        nameRules: [
          (v) => !!v || this.$t("HOMEPAGE_BANNER.ADD_EDIT.NAME_VALIDATION"),
          (v) => (v && v.length <= 50) || this.$t("HOMEPAGE_BANNER.ADD_EDIT.NAME_COUNTER"),
        ],
        descriptionRules: [
          (v)  => (!v || (v && v.length <= 200)) || this.$t("HOMEPAGE_BANNER.ADD_EDIT.PHONE_NUMBER_COUNTER"),
        ],
        descriptionENRules: [
          (v)  => (!v || (v && v.length <= 200)) || this.$t("HOMEPAGE_BANNER.ADD_EDIT.PHONE_NUMBER_COUNTER"),
        ],
        orderRules: [
          (v)  => !!v || this.$t("HOMEPAGE_BANNER.ADD_EDIT.ORDER_VALIDATION"),
          (v) => v > 0 || this.$t("HOMEPAGE_BANNER.ADD_EDIT.ORDER_GT_0"),
        ],
        startDateRules: [
          (v)  => !!v || this.$t("HOMEPAGE_BANNER.ADD_EDIT.START_DATE_VALIDATION"),
        ],
        endDateRules: [
          (v)  => !!v || this.$t("HOMEPAGE_BANNER.ADD_EDIT.END_DATE_VALIDATION"),
        ],    
        startTimeRules: [
          (v)  => !!v || this.$t("HOMEPAGE_BANNER.ADD_EDIT.START_TIME_VALIDATION"),
        ],
        endTimeRules: [
          (v)  => !!v || this.$t("HOMEPAGE_BANNER.ADD_EDIT.END_TIME_VALIDATION"),
        ], 
        externalLinkRules: [
          (v)  => (!v || (v && v.length <= 450)) || this.$t("HOMEPAGE_BANNER.ADD_EDIT.EXTERNAL_LINK_COUNTER"),
        ],
        typeLinkItems: [
          {
            text: this.$t("SYS_VARIABLE.PROMOTION"),
            value: "PROMOTION",
          },
          {
            text: this.$t("SYS_VARIABLE.MENU"),
            value: "MENU",
          },
          {
            text: this.$t("SYS_VARIABLE.EXTERNAL"),
            value: "EXTERNAL",
          },
        ],
        friendlyUrl(value){
          console.log('validating value');
          if (/((http|https):\/\/)(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\\/]))?/i.test(value)){
            return true;
          }
          return 'url'
        },
        promotionSearch: "",
        promotionLoading: false,
        promotionItems: [],
        menuSearch: "",
        menuLoading: false,
        menuItems: [],
      }
    }
  },
  computed: {
    title() {
      return this.$t("MENU.HOMEPAGE_BANNER.EDIT");
    },
    computedStartDateFormatted() {
      return this.formatDate(this.form.startDate)
    },
    computedEndDateFormatted() {
      return this.formatDate(this.form.endDate)
    },
  },
  watch: {
    "form.startDate": {
      handler() {
        this.form.startDateFormatted = this.formatDate(this.form.startDate);
      }
    },
    "form.endDate": {
      handler() {
         this.form.endDateFormatted = this.formatDate(this.form.endDate);
      }
    },
    "form.promotionSearch": {
      handler() {
        this.getPromotionFromApi().then((data) => {
          this.form.promotionItems = data;
        });
      },
    },
    "form.menuSearch": {
      handler() {
        this.getMenuFromApi().then((data) => {
          this.form.menuItems = data;
        });
      },
    },
  },
  methods: {
    formatDate(date) {
      //console.log("formatDate :" + date);
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      //console.log("parseDate :" + date);
      if (!date) return null;

      const [day, month, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    getTomorrowDate() {
      var today = new Date();
      var tomorrow = today;
      tomorrow.setDate(new Date().getDate()+1);
      return tomorrow;
    },
    getFileBase64(file){
      return new Promise(resolve => {
        let reader = new FileReader();
        reader.onload = () => {
          resolve({
              url: reader.result,
              base64: reader.result.split(',')[1],
              contentType: file.name.split('.')[1],
              size: file.size,
            });
        };
        reader.readAsDataURL(file);
      });
    },
    getImageMetadata(file){
      return new Promise(reslove => {
        let image = new Image();
        image.onload = () => {
          //console.log(image);
          reslove({
            w: image.width,
            h: image.height,
          });
        };
        image.src = file;
      });
    },
    submitForm() {
      var chk = this.$refs.form.validate();
      this.form.errors = [];
      var sdStr = this.form.startDate + " " + this.form.startTime;
      var sd = new Date(sdStr);
      var edStr = this.form.endDate + " " + this.form.endTime;
      var ed = new Date(edStr);
      var fileBase64TH = null;
      var fileBase64EN = null;
      
      //console.log("startDate : " + sd);
      //console.log("endDate : " + ed);

       if(sd.getTime() > ed.getTime()){
         this.form.errors.push("กรุณาเลือกวันที่เริ่มการแสดงผลที่น้อยกว่าวันที่สิ้นสุดการแสดงผล");
       }

      if(this.form.typeLink ? this.form.typeLink.value === 'PROMOTION' : false){
        this.form.urlLink = this.form.promotionLink ? this.form.promotionLink.value : null;
      }else if(this.form.typeLink ? this.form.typeLink.value === 'MENU' : false){
        this.form.urlLink = this.form.menuLink ? this.form.menuLink.value : null;
      }else if(this.form.typeLink ? this.form.typeLink.value === 'EXTERNAL' : false){
        if(!this.validateUrl(this.form.externalLink))
          this.form.errors.push("กรุณาใส่ค่าเชื่อมโยงกับในรูปแบบลิงค์");
        else 
          this.form.urlLink = this.form.externalLink;
      }

      if(this.form.picture){
        if(!this.form.pictureType.includes(this.form.picture.type)){
          this.form.errors.push("กรุณาเลือกรูป Format .jpg, .jpeg, .png เท่านั้น");
        }

        this.getFileBase64(this.form.picture).then(file => {
          this.getImageMetadata(file.url).then(image => {
            let mb = 2*1024*1024;
            if(file.size > mb)
              this.form.errors.push("กรุณาเลือกรูปที่มีขนาดน้อยกว่าหรือเท่ากับ 2 MB"); 

            if(image.h > image.w)
              this.form.errors.push("กรุณาเลือกรูปที่มีขนาดของความกว้างมากกว่าความสูง (รูปแนวนอน)"); 

            if(this.form.errors.length > 0)
            {
              this.$vuetify.goTo(this.$refs.alert,{duration : 1000, easing: 'easeInOutCubic', offset: -20});
              chk = false;
            }
             if(this.form.pictureEN)
             {
                this.getFileBase64(this.form.pictureEN).then(fileEn => {
                  this.getImageMetadata(fileEn.url).then(image => {
                    let mb = 2*1024*1024;
                    if(fileEn.size > mb)
                      this.form.errors.push("กรุณาเลือกรูปที่มีขนาดน้อยกว่าหรือเท่ากับ 2 MB"); 

                    if(image.h > image.w)
                      this.form.errors.push("กรุณาเลือกรูปที่มีขนาดของความกว้างมากกว่าความสูง (รูปแนวนอน)"); 

                    if(this.form.errors.length > 0)
                    {
                      this.$vuetify.goTo(this.$refs.alert,{duration : 1000, easing: 'easeInOutCubic', offset: -20});
                      chk = false;
                    }
                    if(chk) 
                    {
                      this.postDataToApi(file ,fileEn);
                    }
                  });
                });
              }
              if(chk && !this.form.pictureEN) 
              {
                this.postDataToApi(file);
              }
          });
        });
      }
       else if(!this.form.picture && this.form.pictureEN)
             {
                this.getFileBase64(this.form.pictureEN).then(fileEn => {
                  this.getImageMetadata(fileEn.url).then(image => {
                    let mb = 2*1024*1024;
                    if(fileEn.size > mb)
                      this.form.errors.push("กรุณาเลือกรูปที่มีขนาดน้อยกว่าหรือเท่ากับ 2 MB"); 

                    if(image.h > image.w)
                      this.form.errors.push("กรุณาเลือกรูปที่มีขนาดของความกว้างมากกว่าความสูง (รูปแนวนอน)"); 

                    if(this.form.errors.length > 0)
                    {
                      this.$vuetify.goTo(this.$refs.alert,{duration : 1000, easing: 'easeInOutCubic', offset: -20});
                      chk = false;
                    }
                    if(chk) 
                    {
                      this.postDataToApi(null,fileEn);
                    }
                  });
                });
              }
 
      else{
        if(this.form.errors.length > 0)
        {
          this.$vuetify.goTo(this.$refs.alert,{duration : 1000, easing: 'easeInOutCubic', offset: -20});
          chk = false;
        }

        if(chk) {
          this.postDataToApi(fileBase64TH ,fileBase64EN);
        }
      }
    },
    resetForm() {
      this.$refs.form.reset();
    },
    postDataToApi(fileBase64TH ,fileBase64EN) {
      this.form.loading = true;
      this.form.errors = [];
      
      ApiService.setHeader();
      ApiService.put("/Api/HomepageBanner/Edit", 
      {
        BannerId: this.form.bannerId,
        Name: this.form.name,
        Description: this.form.description,
        DescriptionEN : this.form.descriptionEN,
        Order: Number(this.form.order),
        StartDateString: this.form.startDateFormatted,
        EndDateString: this.form.endDateFormatted,
        StartTimeString: this.form.startTime,
        EndTimeString: this.form.endTime,
        Picture: fileBase64TH == null ? null : fileBase64TH.base64,
        ContentType: fileBase64TH == null ? null : fileBase64TH.contentType,
        pictureEN: fileBase64EN == null ? null : fileBase64EN.base64,
        ContentTypeEN: fileBase64EN == null ? null : fileBase64EN.contentType,
        InActiveStatus: !this.form.inActiveStatus,  
        TypeLink: this.form.typeLink
            ? this.form.typeLink.value
            : null,
        UrlLink: this.form.urlLink, 
      })
      .then(({ data }) => {
        if (data.status) {
          // close dialog
          this.form.dialog = true;
        } else {
          this.form.dialog = false;
          this.form.loading = false;
          this.form.errors.push(!!data.message || "Unknow error occurs");
          this.$vuetify.goTo(this.$refs.alert,{duration : 1000, easing: 'easeInOutCubic', offset: -20});
        }
      })
      .catch(({ response }) => {
        if (response.data) {
          this.form.errors.push(response.data.message);
        } else {
          this.form.errors.push("Unknow error occurs");
        }
        this.$vuetify.goTo(this.$refs.alert,{duration : 1000, easing: 'easeInOutCubic', offset: -20});
        this.form.dialog = false;
        this.form.loading = false;
      });
    },
    closeDialog() {
      // not close but redirect to search page
      this.$router.push({ name: "SearchHomepageBanner" });
    },
    getDataFromApi(id) {
      this.form.loading = true;
      return new Promise(() => {
        ApiService.setHeader();
        ApiService.get("/Api/HomepageBanner", id)
          .then(({ data }) => {
            let st = data.startDateString.split(" ");
            let et = data.endDateString.split(" ");
            
            this.form.bannerId = data.bannerId;
            this.form.name = data.name;
            this.form.description = data.description;
            this.form.descriptionEN = data.descriptionEN;
            this.form.order = data.order;
            this.form.startDateFormatted = st[0];
            this.form.startTime = st[1].substring(0, 5);
            this.form.endDateFormatted = et[0];
            this.form.endTime = et[1].substring(0, 5);
            this.form.inActiveStatus = !data.inActiveStatus;
            this.form.startDate = this.parseDate(this.form.startDateFormatted);
            this.form.endDate = this.parseDate(this.form.endDateFormatted);
            this.form.pictureUrl = data.downloadFileByteUrl;
            this.form.pictureENUrl = data.downloadFileENByteUrl;

            if(data.typeLink === 'PROMOTION'){
              this.form.typeLink = {
                text: this.$t("SYS_VARIABLE.PROMOTION"),
                value: data.typeLink
              };
              this.form.promotionLink = {
                //text: "",
                value: data.urlLink
              };
            }else if(data.typeLink === 'MENU'){
              this.form.typeLink = {
                text: this.$t("SYS_VARIABLE.MENU"),
                value: data.typeLink
              };
              this.form.menuLink = {
                //text: "",
                value: data.urlLink
              };
            }else if(data.typeLink === 'EXTERNAL'){
              this.form.typeLink = {
                text: this.$t("SYS_VARIABLE.EXTERNAL"),
                value: data.typeLink
              };
              this.form.externalLink = data.urlLink;
            }
          })
          .finally(() => {
            this.form.loading = false;
          });
      });
    },
    getPromotionFromApi() {
      return new Promise((resolve) => {
        ApiService.setHeader();
        ApiService.post("/Api/Promotion/PromotionOptions", {
          query: this.form.promotionSearch,
        })
          .then(({ data }) => {
            resolve(data);
          })
          .finally(() => {
            this.form.promotionLoading = false;
          });
      });
    },
    getMenuFromApi() {
      return new Promise((resolve) => {
        ApiService.setHeader();
        ApiService.post("/Api/Menu/MenuOptions", {
          query: this.form.menuSearch,
        })
          .then(({ data }) => {
            resolve(data);
          })
          .finally(() => {
            this.form.menuLoading = false;
          });
      });
    },
    validateUrl(value) {
      var url_pattern = /((http|https):\/\/)(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\\/]))?/i;
      return value.match(url_pattern);
    },
    clearUrlLink() {
      if(this.form.typeLink ? this.form.typeLink.value === 'PROMOTION' : false){
        //console.log("PROMOTION clear");
        this.form.menuLink = null;
        this.form.externalLink = null;
      }else if(this.form.typeLink ? this.form.typeLink.value === 'MENU' : false){
        //console.log("MENU clear");
        this.form.promotionLink = null;
        this.form.externalLink = null;
      }else if(this.form.typeLink ? this.form.typeLink.value === 'EXTERNAL' : false){
        //console.log("EXTERNAL clear");
        this.form.promotionLink = null;
        this.form.menuLink = null;
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.HOMEPAGE_BANNER.SECTION"), route: "/HomepageBanner" },
      { title: this.$t("MENU.HOMEPAGE_BANNER.EDIT") },
    ]);

    this.getDataFromApi(this.$route.params.id);
  },
};
</script>

<style lang="scss" scoped></style>
